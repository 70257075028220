.container {
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.inputWrapper {
  display: flex;
  align-items: center;
}

.label {
  font-size: 1.2rem;
  font-weight: 600;
  margin-right: 1rem;
  text-transform: uppercase;
  position: relative;

  &::after {
    content: ":";
  }
}

.reset {
  position: relative;
  width: 2rem;
  height: 2rem;
  background-color: transparent;
  border: 0;

  &::before, &::after {
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 1rem;
    height: .2rem;
    background-color: var(--red);
  }

  &::before {
    transform: translate(-50%, -50%) rotate(-45deg);
  }

  &::after {
    transform: translate(-50%, -50%) rotate(45deg);
  }
}
