.tabs {
  display: flex;
}

.tab {
  flex: 1;
  flex-basis: 50%;
  border-bottom: 0;
  background-color: var(--foreground);
  color: var(--background);
  opacity: .5;
  font-weight: 600;

  &.active {
    background-color: var(--background);
    color: var(--foreground);
    opacity: 1;
    border-top-width: .3rem;
    border-top-color: var(--brand);
  }
}

.panel {
  display: none;
  margin-bottom: 1rem;
  padding: 1rem;
  border: 1px solid var(--foreground);
  border-top: 0;

  &.active {
    display: block;
  }
}

.prompt {
  width: 100%;
  margin-bottom: .5rem;
}

.adapterStrength {
  margin-bottom: 1rem;
}

.footer {
  display: flex;
  gap: 1rem;
}

.seed, .submit {
  flex: 1;
}
