:root {
  // Colors
  --black: #303030;
  --white: #ffffff;
  --brand: #6963f3;
  --red: #e75757;

  --background: var(--white);
  --foreground: var(--black);

  // Fonts
  --font-size: 1.6rem;
  --font-weight: 300;

  --body-font: 'League Spartan', sans-serif;
  --heading-font: 'League Spartan', sans-serif;

  // Utils
  --border-radius: .3rem;
}

.dark-mode {
  --background: var(--black);
  --foreground: var(--white);
}
