.select {
  border: 1px solid var(--foreground);
  border-radius: 0;
  appearance: none;
  padding: .5rem;
  position: relative;
  font-size: 1.2rem;
  line-height: 1.625;

  background-image:
    linear-gradient(45deg, transparent 50%, gray 50%),
    linear-gradient(135deg, gray 50%, transparent 50%),
    linear-gradient(to right, #ccc, #ccc);
  background-position:
    calc(100% - 1.6rem) 1.3rem,
    calc(100% - 1.1rem) 1.3rem,
    calc(100% - 3rem) .5rem;
  background-size:
    .5rem .5rem,
    .5rem .5rem,
    .1rem 2rem;
  background-repeat: no-repeat;
}
