.container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  aspect-ratio: 1 / 1;
}

.canvas {
  width: 100%;
  height: 100%;
}

.download {
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: var(--background);
  padding: 1rem;
  font-size: 1.2rem;
  text-transform: uppercase;
  font-weight: 600;
}
