.container {
  display: flex;
  align-items: center;
}

.label {
  font-size: 1.2rem;
  font-weight: 600;
  margin-right: 1rem;
  text-transform: uppercase;

  &::after {
    content: ":";
  }
}

.output {
  margin-left: .5rem;
  font-weight: 500;
}
