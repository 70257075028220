.button {
  background-color: var(--background);
  border-radius: 0;
  border: 1px solid var(--foreground);
  text-transform: uppercase;
  padding: .75rem;
  font-weight: 600;
}

.primary {
  background-color: var(--foreground);
  color: var(--background);
}

.disabled {
  opacity: .5;
  cursor: not-allowed;

  background-color: var(--background);
  color: var(--foreground);
}
