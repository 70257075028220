.layout {
  padding-top: 1rem;
  padding-bottom: 2rem;
  width: 95%;
  margin: 0 auto;

  @media screen and (min-width: 650px) {
    max-width: 72rem;
  }
}

.header {
  margin-top: 1rem;
  margin-bottom: 2rem;
}

.icon {
  width: 3rem;
  height: 3rem;
  vertical-align: top;
}

.title {
  font-size: 3rem;
  margin-bottom: .5rem;
  color: var(--brand);
  font-weight: 600;
  text-transform: uppercase;
}

.subline {
  font-size: 1.4rem;
}

.contact {
  color: darkblue;
  text-decoration: underline;
}

.image {
  margin-bottom: 1rem;

  @media screen and (min-width: 650px) {
    display: flex;
    gap: 1rem;
  }
}

.form, .preview {
  flex: 1;
  flex-basis: 50%;
}

.genmesh {
  width: 100%;
  margin-bottom: 1rem;
}
