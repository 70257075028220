*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
  -webkit-font-smoothing: antialiased;
}

body {
  color: var(--foreground);
  font-family: var(--body-font);
  font-size: var(--font-size);
  font-weight: var(--font-weight);
  background: var(--background);
}

a {
  color: inherit;
  text-decoration: none;
}

button {
  font-family: var(--body-font);
  cursor: pointer;
}

